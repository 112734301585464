import React, { useEffect, useState } from "react";
import { Row, Card, CardBody, Badge, Modal,
    ModalHeader,
    ModalBody, ModalFooter, Button,
    Label, Input } from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import axios from 'axios';
import ReactTable from "react-table";
import { NotificationManager } from "../../components/common/react-notifications";
import { connect } from "react-redux";
import * as FileSaver from 'file-saver';

import DataTablePagination from "../../components/DatatablePagination";
import ProductsAutoSuggest from "../ProductsAutoSuggest";

const payments_url = 'https://api.tuatuagye.com/payments';
const img_base_url = 'https://api.tuatuagye.com/get-image?type=product&id='

const myProducts_url = 'https://api.tuatuagye.com/m-products';
const updateMyProducts_url = 'https://api.tuatuagye.com/update-my-product';
const delivery_url = 'https://api.tuatuagye.com/deliveries';
// const update_ttg_bal = 'https://api.tuatuagye.com/update-ttg-bal';
const swap_my_product_url = 'https://api.tuatuagye.com/swap-my-product'

const formatAmount = (amount) => {
  if(!amount) return '0.00';
  if(amount > 999){
    let temp = amount.toString();
    let decimal = '.00';

    if(temp.indexOf('.') > -1){
      decimal = '.'+temp.split('.')[1];
      temp = temp.split('.')[0];
    }
    
    temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`
    return temp;
  }
  
  return Number(amount).toFixed(2);
}

const __getTime = (_date) => {
  // 3:16pm
  if(!_date) return '';
  const date = new Date(_date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  
  let strTime = hours + ':' + minutes + '' + ampm;
  
  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${strTime}`;
}

const __getDate = (_date) => {
  // Mar 2
  if(!_date) return '';
  const date = new Date(_date);

  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${months[date.getMonth()]} ${date.getDate()}`;
}


// const formatDate = (_date) => {
//   // 3:16pm | Mar 2 2021
//   if(!_date) return '';
//   const date = new Date(_date);
//   let hours = date.getHours();
//   let minutes = date.getMinutes();
//   let ampm = hours >= 12 ? 'pm' : 'am';
//   hours = hours % 12;
//   hours = hours ? hours : 12; // the hour '0' should be '12'
//   minutes = minutes < 10 ? '0'+minutes : minutes;
  
//   let strTime = hours + ':' + minutes + '' + ampm;
  
//   let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
//   return `${strTime} | ${months[date.getMonth()]} ${date.getDate()}`;
// }

const __formatDate = (_date) => {
  // 3:16pm | Mar 2 2021
  if(!_date) return '';
  const date = new Date(_date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  
  let strTime = hours + ':' + minutes + '' + ampm;
  
  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  let yr = `${date.getFullYear()}`.slice(2);
  return `${strTime} | ${months[date.getMonth()]} ${date.getDate()}, ${yr}`;
}


const ProductItem = ({ admin, product, id, currentPrice, amountPaid, productName, createdAt, status, toggleModal, toggleSwapModal, togglePriceChangesModal, toggleUpdateModal, swapHistory, toggleCloseProductModal, toggleSwapProductModal }) => {
    return (
    <Card className="d-flex flex-row mb-3">
      <div className="d-flex flex-grow-1 min-width-zero">
        <CardBody className="align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
          <p className="list-item-heading mb-1 truncate w-40 w-xs-100">
            <img style={{width:60,height:60,borderRadius:5,marginRight:10,backgroundColor:'#ccc'}} src={`${img_base_url}${product.thumbnail}`} alt={'Image'}/>
            {productName}
          </p>
          <p className="mb-1 w-15 w-xs-100" style={{color:'#2ECC71'}}><b>GH₵ {formatAmount(amountPaid)} / <span style={{color:'#a13'}}>GH₵ {currentPrice}</span></b></p>
          <p className="mb-1 text-muted w-15 w-xs-100">{__formatDate(createdAt)}</p>
          <p className="mb-1 text-muted">
            <span onClick={()=> toggleModal(product, id)} style={{fontSize:13,cursor:'pointer',marginRight:15,color:'#000'}}>View Payments</span>
          </p>
          <p className="mb-1 text-muted">
            <span onClick={()=> toggleSwapModal(product, swapHistory)} style={{fontSize:13,cursor:'pointer'}}>Swap History</span>
          </p>
          <p className="mb-1 text-muted">
            <span onClick={()=> togglePriceChangesModal(product)} style={{fontSize:13,cursor:'pointer'}}>Price Changes</span>
          </p>

          <div className="text-right mr-2">
            <Badge color={status === 1 ? 'warning': status === 2 ? 'success' : status === 3 ? 'secondary' : status === 4 ? 'success' : 'danger'} pill>
              {status === 1 ? 'IN PRGS': status === 2 ? 'CMPLTD' : status === 3 ? 'SHPD' : status === 4 ? 'DLVD' : status === 9 ? 'CLSD' : 'BLANK'}
            </Badge>
          </div>

          {(admin.type == 1 || (admin.permissions && admin.permissions['UPDATE_CUSTOMER_BALANCE'] > -1)) && status < 4 && (
            <p className="mb-1 text-muted">
              <span onClick={()=> toggleUpdateModal({...product, currentPrice, amountPaid, myProductId: id})} style={{fontSize:13,cursor:'pointer',marginLeft:10}}>Update </span>
            </p>
          )}

          {(admin.type == 1 || (admin.permissions && admin.permissions['DELETE_CUSTOMER_PRODUCT'] > -1)) && status < 4 && (<p className="mb-1 text-muted">
            <span onClick={()=> toggleCloseProductModal({...product, currentPrice, amountPaid, myProductId: id})} style={{fontSize:13,cursor:'pointer',marginLeft:10,color:'#f00'}}>Delete</span>
          </p>)}

          {(admin.type == 1 || (admin.permissions && admin.permissions['SWAP_CUSTOMER_PRODUCT'] > -1)) && status < 4 && (<p className="mb-1 text-muted">
            <span onClick={()=> toggleSwapProductModal({...product, currentPrice, amountPaid, myProductId: id, swapHistory})} style={{fontSize:13,cursor:'pointer',marginLeft:10,color:'#00f'}}>Swap</span>
          </p>)}
        </CardBody>
      </div>
    </Card>
  );
};

const CustomerProducts = ({ data, user, admin, updateProduct, deleteProduct }) => {
    const [openModal, setOpenModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [newProduct, setNewProduct] = useState({});
    const [loadingPayments, setLoadingPayments] = useState(true);
    const [openSwapModal, setOpenSwapModal] = useState(false);
    const [openPriceChangesModal, setOpenPriceChangesModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [payments, setPayments] = useState([]);
    const [totalPayments, setTotalPayments] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);
    const [currentPrice, setCurrentPrice] = useState(0);
    const [updating, setUpdating] = useState(false);
    const [adminCode, setAdminCode] = useState('');
    const [reason, setReason] = useState('');
    const [closeProductModal, setCloseProductModal] = useState(false);
    const [closing, setClosing] = useState(false);
    const [showSwap, setShowSwap] = useState(false);
    const [swaping, setSwaping] = useState(false);
    const [processingSwap, setProcessingSwap] = useState(true);
    const [productList, setProductList] = useState([])

    useEffect(()=> {
      setProductList(data);
    }, [data])

    const toggleModal = (product, myProductId) => {
        setSelectedProduct(product);
        if(!openModal){
          setLoadingPayments(true);
          loadPayments(myProductId)
        }
        setOpenModal(!openModal);
    };

    const toggleSwapModal = (product, swapHistory) => {
      setSelectedProduct({...product, swapHistory});
      setOpenSwapModal(!openSwapModal);
    };

    const onNewProductSelected = (val) => {
      setNewProduct(val);
    }
    const togglePriceChangesModal = (product) => {
      setSelectedProduct(product);
      setOpenPriceChangesModal(!openPriceChangesModal);
    }

    const toggleCloseProductModal = (product) => {
      setSelectedProduct(product);
      setClosing(false)
      setCloseProductModal(!closeProductModal);
    };

    const toggleSwapProductModal = (product) => {
      setSelectedProduct(product);
      // setClosing(false)
      setShowSwap(!showSwap);
    };

    const toggleUpdateModal = (product) => {
      // console.log('pro',product);
      setAmountPaid(product.amountPaid);
      setCurrentPrice(product.currentPrice);
      setSelectedProduct(product);
      setUpdating(false)
      setOpenUpdateModal(!openUpdateModal);
    };

    const handleCloseProduct = () => {
      const { myProductId } = selectedProduct;
      
      if(myProductId){
        setClosing(true);

        axios.get(`${myProducts_url}?filter[where][id]=${myProductId}`)
        .then(res=> {
          if(res.data && res.data.length > 0){
            
            return axios.post(updateMyProducts_url, {id: myProductId, update: {status: 9}})
          }
          else {
            return null;
          }
        })
        .then(res=> {
          if(res){
            deleteProduct(myProductId);
            NotificationManager.success(
              "Product Deleted",
              "You have successfully closed this product",
              3000,
              null,
              null,
            );

            setAdminCode('');
            setCloseProductModal(false);
          }
        })
        .catch(err=> {
          console.log('err...',err);
          setClosing(false);
          NotificationManager.error(
            "Processing Failed",
            "An unknown error occurred. Please try again. Thank you.",
            3000,
            null,
            null,
          );
        })
      }
      else {
        NotificationManager.error(
          "Unknown error",
          "Could not close product",
          3000,
          null,
          null,
        );
      }
    }

    const handleUpdate = () => {
      let amtPaid = Number(amountPaid);
      let cPrice = Number(currentPrice);
      if(amtPaid > cPrice){
        NotificationManager.error(
          "Not allowed",
          "Amount paid cannot be greater than current price",
          3000,
          null,
          null,
        );
        return;
      }
      else if(!adminCode){
        NotificationManager.error(
            "Authorization Code",
            "Your security code required to perform this transaction. Thank you.",
            3000,
            null,
            null,
        );
        return;
      }
      else if(adminCode !== admin.authCode) {
        NotificationManager.error(
            "Invalid Code",
            "Sorry, the code you entered is invalid. Check and try again.",
            3000,
            null,
            null,
        );
        return;
      }

      const { myProductId } = selectedProduct;

      const update =  {
        amountPaid: amtPaid,
        currentPrice: cPrice,
        percentageCompleted: (amtPaid / cPrice) * 100,
        status: amtPaid < cPrice ? 1 : 2,
      }

      setUpdating(true);
      
      if(myProductId){
        axios.get(`${myProducts_url}?filter[where][id]=${myProductId}`)
        .then(async res=> {
          if(res.data && res.data.length > 0){
            const myPro = res.data[0];
            // return {id:9}
            if(update.percentageCompleted >= 100){
              const newDelivery = {
                  buyerId: user.id,
                  buyerName: `${user.firstname} ${user.lastname}`,
                  quantity: 1,
                  type: 1,
                  status: 1,
                  productId: myPro.productId,
                  productName: myPro.productName,
                  myProductId
              }

              // console.log('new delivery is',newDelivery)
              await axios.post(`${delivery_url}`, newDelivery);
            }

            const payload = {
              id: myProductId,
              update: {
                amountPaid: amtPaid,
                currentPrice: cPrice,
                percentageCompleted: (amtPaid / cPrice) * 100,
                status: amtPaid < cPrice ? 1 : 2,
              }
            }

            return axios.post(updateMyProducts_url,payload)
          }
          else {
            return null;
          }
        })
        .then(async res=> {
          if(res){
            updateProduct({id: myProductId, ...update});
            NotificationManager.success(
              "Product Updated",
              "You have successfully updated this product",
              3000,
              null,
              null,
            );

            setAdminCode('');
            setOpenUpdateModal(false);
          }
        })
        .catch(err=> {
          console.log('err...',err);
          setClosing(false);
          NotificationManager.error(
            "Processing Failed",
            "An unknown error occurred. Please try again. Thank you.",
            3000,
            null,
            null,
          );
        })
      }
      else {
        NotificationManager.error(
          "Unknown error",
          "Could not update product",
          3000,
          null,
          null,
        );
      }
    }

    const handleSwapProduct = () => {
      // console.log('new pro', newProduct);
      // console.log('old p',selectedProduct)
      
      const { amountPaid, swapHistory } = selectedProduct;

      const swap = {
        oldProductName: selectedProduct.name,
        newProductName: newProduct.name,
        date: new Date().toJSON(),
        byId: admin.id,
        byName: `${admin.firstname} ${admin.lastname}` 
      }

      const ttgCash = user.ttgCash || 0;

      // console.log('u',user.type);
      let updatePayload = {};
      if(user.type == 5){
        updatePayload = {
          id: selectedProduct.myProductId,
          productName: newProduct.name,
          initialPrice: newProduct.onlineTTGPrice,
          currentPrice: newProduct.onlineTTGPrice,
          amountPaid: newProduct.onlineTTGPrice > amountPaid ? (amountPaid+ttgCash) : newProduct.onlineTTGPrice,
          status: newProduct.onlineTTGPrice > (amountPaid+ttgCash) ? 1 : 2,
          productId: newProduct.id,
          percentageCompleted: newProduct.onlineTTGPrice > (amountPaid+ttgCash) ? (((amountPaid+ttgCash) / newProduct.onlineTTGPrice) * 100) : 100,
          swapHistory: swapHistory ? [swap, ...swapHistory] : [swap],
          canSwap: false,
          swapAttempts: 2
        }
      }
      else {
        updatePayload = {
          id: selectedProduct.myProductId,
          productName: newProduct.name,
          initialPrice: newProduct.posTTGPrice,
          currentPrice: newProduct.posTTGPrice,
          amountPaid: newProduct.posTTGPrice > amountPaid ? (amountPaid+ttgCash) : newProduct.posTTGPrice,
          status: newProduct.posTTGPrice > (amountPaid+ttgCash) ? 1 : 2,
          productId: newProduct.id,
          percentageCompleted: newProduct.posTTGPrice > (amountPaid+ttgCash) ? (((amountPaid+ttgCash) / newProduct.posTTGPrice) * 100) : 100,
          swapHistory: swapHistory ? [swap, ...swapHistory] : [swap],
          canSwap: false,
          swapAttempts: 2
        }
      }

      let ttgCashBalance = 0;

      // console.log('pl',updatePayload);
      setSwaping(true);
      // console.log('mp id',selectedProduct.myProductId);

      if(!selectedProduct.myProductId){
        alert('An error occurred. Contact developer');
        return;
      }

      axios.post(swap_my_product_url, updatePayload)
        // .then(res => {
        //   if(user.type == 5){
        //     if(newProduct.onlineTTGPrice < amountPaid){
        //       ttgCashBalance = ttgCash + (amountPaid - newProduct.onlineTTGPrice);
        //     }
        //     else if(newProduct.onlineTTGPrice > amountPaid) {
        //         if(newProduct.onlineTTGPrice > (amountPaid+ttgCash)){
        //             ttgCashBalance = 0
        //         }
        //         else {
        //             ttgCashBalance = (amountPaid + ttgCash) - newProduct.onlineTTGPrice;
        //         }
        //     }
        //   }
        //   else {
        //     if(newProduct.posTTGPrice < amountPaid){
        //       ttgCashBalance = ttgCash + (amountPaid - newProduct.posTTGPrice);
        //     }
        //     else if(newProduct.posTTGPrice > amountPaid) {
        //       if(newProduct.posTTGPrice > (amountPaid+ttgCash)){
        //           ttgCashBalance = 0
        //       }
        //       else {
        //           ttgCashBalance = (amountPaid + ttgCash) - selectedProduct.posTTGPrice;
        //       }
        //     }
        //   }

        //   console.log('ttgCash now is',ttgCashBalance);

        //   if(ttgCashBalance !== ttgCash){
        //       return axios.post(update_ttg_bal, {id: user.id, amount: ttgCashBalance})
        //   }
        //   else {
        //       console.log('no ttg cash update');
        //       return null;
        //   }
        // })
        .then(res=> {
            if(res && ((user.type == 5 && (newProduct.onlineTTGPrice <= amountPaid)) || (user.type == 6 && (newProduct.posTTGPrice <= amountPaid)))){
              // const swapPayload = {
              //     oldProductId: selectedProduct.myProductId, 
              //     newAmount: user.type == 5 ? newProduct.onlineTTGPrice : newProduct.posTTGPrice, 
              //     id: user.id, 
              //     customerName: `${user.firstname} ${user.lastname}`,
              //     productId: newProduct.id,
              //     productName: newProduct.name
              // }
              //console.log('in here...',swapPayload);
              const newDelivery = {
                buyerId: user.id,
                buyerName: `${user.firstname} ${user.lastname}`,
                quantity: 1,
                type: 1,
                status: 1,
                productId: newProduct.id,
                productName: newProduct.name,
                myProductId: selectedProduct.myProductId
              }
              
              console.log('new delv',newDelivery);
              return axios.post(delivery_url, newDelivery)
            }
            else {
              console.log('no swap-delivery');
              return null;
            }
        })
        .then(res => {
          const _data = productList.map(d=> {
            if(d.id == selectedProduct.myProductId){
              const newData = {
                ...d,
                ...updatePayload
              }
              console.log('new data is',newData);
              return newData;
            }
            return d;
          });

          setProductList(_data)
          console.log('new products data is',_data);

          NotificationManager.success(
            "Swap Success",
            "You have successfully swapped this product",
            3000,
            null,
            null,
          );

          setShowSwap(false)
        })
        .catch(err => {
            console.log('err',err);
            NotificationManager.error(
              "Swap Error",
              "An error occurred. Kindly try again.",
              3000,
              null,
              null,
            );
        })
        .finally(()=> setSwaping(false))
    }

    const loadPayments = (id) => {
      // console.log('user',user);
      // console.log('m pro id',id);
      axios.get(`${payments_url}?filter[where][and][0][myProductId]=${id}&filter[where][and][1][payeeId]=${user.id}&filter[order]=createdAt%20DESC`)
      .then(res => {
        if(res.data && res.data.length > 0){
          let _total = 0;
          // console.log('user is',user);
          // console.log('res data',res.data);
          res.data.forEach(payment => _total += payment.amount);
          setTotalPayments(_total)
          setPayments(res.data);
          setLoadingPayments(false);
        }
        else {
          setPayments([]);
          setLoadingPayments(false);
        }
      })
      .catch(error => {
        setLoadingPayments(false);
        NotificationManager.error(
          "Loading error",
          "An error occurred. Kindly try again.",
          3000,
          null,
          null,
        );
        console.log('error...',error);
      })
    }

    const dataTableColumns = [
      {
        Header: "N#",
        accessor: "",
        width:50,
        Cell: props => <p className="">{props.row._index + 1}.</p>
      },
      {
        Header: "Amount",
        accessor: "amount",
        width:80,
        Cell: props => <p className="text-muted">{formatAmount(props.value)}</p>
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: props => <p className="text-muted">{__formatDate(props.value)}</p>
      },
      {
        Header: "Mode",
        accessor: "mode",
        width:100,
        Cell: props => <p className="text-muted">{props.value}</p>
      },
      {
        Header: "MoMo N#",
        accessor: "momoNumber",
        width:100,
        Cell: props => <p className="text-muted">{props.value}</p>
      }
    ];
  
    const exportToExcel = () => {
      let customer = 'Customer'
      const data = payments.map((record, i)=> {
        if(i == 0) {
          customer = record.payeeName
        }
        return {
          Amount: record.amount,
          Number: record.momoNumber,
          Mode: record.mode,
          // Location: record.user.location,
          // Occupation: record.user.occupation,
          // Product: record.productName,
          'Date': __formatDate(record.createdAt)
        }
      })
      import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(data);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          saveAsExcelFile(excelBuffer, `${customer} Payments Data - ${new Date().toJSON()}`);
      });
    }
  
    const saveAsExcelFile = (buffer, fileName) => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
          type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    }
  
  return (
    <Row>
      <Colxx>
        {productList.map((myProduct, index) => {

          if(myProduct.product){
            return <ProductItem key={`_${index}`} {...myProduct} toggleModal={toggleModal} toggleSwapModal={toggleSwapModal} togglePriceChangesModal={togglePriceChangesModal} toggleUpdateModal={toggleUpdateModal} toggleCloseProductModal={toggleCloseProductModal} toggleSwapProductModal={toggleSwapProductModal} admin={admin}/>;
          }
          else return <div><p>Error with product details</p></div>;
        })}
      </Colxx>

      {openModal && (<Modal
        style={{width: 500}}
        isOpen={openModal}
        toggle={toggleModal}
        wrapClassName="modal-right"
        >
        <ModalHeader style={{alignItems:"center"}} toggle={toggleModal}>
            <img src={`${img_base_url}${selectedProduct.thumbnail}`} alt="Img" style={{width:60,height:60,borderRadius:5,marginRight:5,backgroundColor:'#ccc'}} />
            <Label>
                {selectedProduct && selectedProduct.name}
            </Label>
        </ModalHeader>
        <ModalBody style={{padding:"0px"}}>
            {loadingPayments && <div className='loading'/> }
            {!loadingPayments &&
              <div style={{display:'flex', alignItems:'center',paddingLeft:20,paddingTop:20}}>
                <h5>
                    Total GHS {formatAmount(totalPayments)} 
                </h5>

                <div
                  onClick={()=> {
                    exportToExcel();
                  }} 
                  className="d-flex justify-content-end" style={{flex:1,cursor:"pointer",color:'#008894'}}>
                  Download to Excel 
                  <div style={{marginLeft:5,marginTop:3}} className={"glyph-icon simple-icon-download"}/>
                </div>
              </div>
            }
            <Colxx xxs="12">
            {payments.length > 0 &&
                <ReactTable
                    data={payments}
                    columns={dataTableColumns}
                    defaultPageSize={15}
                    showPageJump={true}
                    PaginationComponent={DataTablePagination}
                    showPageSizeOptions={true}
                />
            }
            </Colxx>
        </ModalBody>
      </Modal>)}

      {openUpdateModal && (<Modal
        style={{width: 400}}
        isOpen={openUpdateModal}
        toggle={toggleUpdateModal}
        wrapClassName="modal-right"
        >
        <ModalHeader style={{alignItems:"center"}} toggle={toggleUpdateModal}>
            <img src={`${img_base_url}${selectedProduct.thumbnail}`} alt="Img" style={{width:60,height:60,borderRadius:5,marginRight:5,backgroundColor:'#ccc'}} />
            <Label>
                {selectedProduct && selectedProduct.name}
            </Label>
        </ModalHeader>
        <ModalBody style={{padding:"0px"}}>
          <div style={{display:'flex',alignItems:'center',marginTop:20,marginRight:20,marginLeft:20}}>
            <h6 style={{marginRight:20}}>Paid:</h6>
            <Input
              disabled={updating}
                style={{backgroundColor:'#eee',width:'70%'}}
                placeholder={""}
                value={amountPaid}
                onChange={e => setAmountPaid(e.target.value)}
            />
          </div>

          <div style={{display:'flex',alignItems:'center',marginTop:20,marginRight:20,marginLeft:20}}>
            <h6 style={{marginRight:20}}>Current Price:</h6>
            <Input
              disabled={updating}
                style={{backgroundColor:'#eee'}}
                placeholder={""}
                value={currentPrice}
                onChange={e => setCurrentPrice(e.target.value)}
            />
          </div>

          <div style={{display:'flex',alignItems:'center',marginTop:20,marginRight:20,marginLeft:20}}>
            <h6 style={{marginRight:20}}>Reason:</h6>
            <Input
              disabled={updating}
              style={{backgroundColor:'#eee'}}
              placeholder={""}
              value={reason}
              onChange={e => setReason(e.target.value)}
            />
          </div>

          <div style={{display:'flex',alignItems:'center',marginTop:20,marginRight:20,marginLeft:20}}>
            <h6 style={{marginRight:20}}>Admin Code:</h6>
            <Input
              disabled={updating}
              type="password"
              style={{backgroundColor:'#eee'}}
              placeholder={""}
              value={adminCode}
              onChange={e => setAdminCode(e.target.value)}
            />
          </div>

          <div style={{display:'flex',justifyContent:'flex-end',marginTop:20,marginRight:20,marginLeft:20}}>
            <button disabled={updating} onClick={handleUpdate} style={{paddingLeft:20,paddingRight:20,paddingTop:10,paddingBottom:10,backgroundColor:'#008894',cursor:'pointer',borderRadius:3,color:'#fff'}}>{updating? 'Please wait...' : 'Save'}</button>
          </div>
        </ModalBody>
      </Modal>)}

      {openSwapModal && (<Modal
          style={{width: 750}}
          isOpen={openSwapModal}
          toggle={toggleSwapModal}
          wrapClassName="modal-right"
          >
          <ModalHeader style={{alignItems:"center"}} toggle={toggleSwapModal}>
              <img src={`${img_base_url}${selectedProduct.thumbnail}`}  alt="Img" style={{width:60,height:60,borderRadius:5,marginRight:5,backgroundColor:'#ccc'}} />
              <Label>
                  {selectedProduct && selectedProduct.name}
              </Label>
          </ModalHeader>
          <ModalBody style={{padding:"0px"}}>              
              {(selectedProduct.swapHistory && selectedProduct.swapHistory.length > 0) && 
                <h5 style={{paddingLeft:20,paddingTop:20,color:'#008894'}}>
                  Swap History
                </h5>
              }

              <Colxx xxs="12">
              {(selectedProduct.swapHistory && selectedProduct.swapHistory.length > 0) ?
                  <ReactTable
                      data={selectedProduct.swapHistory}
                      columns={[
                        {
                          Header: "N#",
                          accessor: "",
                          width: 40,
                          Cell: props => <p className="">{props.row._index + 1}.</p>
                        },
                        {
                          Header: "From",
                          accessor: "oldProductName",
                          Cell: props => <p style={{color:'#a13'}}>{props.value}</p>
                        },
                        {
                          Header: "To",
                          accessor: "newProductName",
                          Cell: props => <p style={{color:'#228b22'}}>{props.value}</p>
                        },
                        {
                          Header: "Date",
                          accessor: "date",
                          width: 90,
                          Cell: props => <p className="text-muted">{__getTime(props.value)} <br/> {__getDate(props.value)}</p>
                        },
                        {
                          Header: "By",
                          accessor: "byId",
                          width:80,
                          Cell: props => <p className="text-muted">{props.value === user.id ? 'SELF' : props.original.byName}</p>
                        },
                      ]}
                      defaultPageSize={5}
                      showPageJump={true}
                      PaginationComponent={DataTablePagination}
                      showPageSizeOptions={true}
                  />
                  :
                  <h5 style={{paddingLeft:20,paddingTop:20}}>No swap history found</h5>
              }
              </Colxx>
          </ModalBody>
        </Modal>)}

        {showSwap && (<Modal
          style={{width: 550}}
          isOpen={showSwap}
          toggle={toggleSwapProductModal}
          wrapClassName="modal-right"
          >
          <ModalHeader style={{alignItems:"center"}} toggle={toggleSwapProductModal}>
            <Label>Swap Customer Product</Label>
          </ModalHeader>
          <ModalBody style={{padding:"0px"}}> 
            {swaping && <div className='loading'/> }             
            <h5 style={{paddingLeft:20,paddingTop:20,marginBottom:20,color:'#008894'}}>
              {selectedProduct.name}
            </h5>

            <Colxx xxs="12">
              <ProductsAutoSuggest onChange={onNewProductSelected} />
            </Colxx>

            <div style={{display:'flex',alignItems:'center',marginTop:20,marginRight:20,marginLeft:20}}>
              <h6 style={{marginRight:20}}>Admin Code:</h6>
              <Input
                disabled={updating}
                type="password"
                style={{backgroundColor:'#eee'}}
                placeholder={""}
                value={adminCode}
                onChange={e => setAdminCode(e.target.value)}
              />
            </div>

          </ModalBody>
          <ModalFooter>
            <Button
              disabled={swaping}
              color="default"
              onClick={toggleSwapProductModal}
            >
              Cancel
            </Button>{" "}
            <Button
              disabled={swaping}
              color="success"
              onClick={()=> {
                if(!adminCode){
                    NotificationManager.error(
                        "Authorization Code",
                        "Your security code required to perform this transaction. Thank you.",
                        3000,
                        null,
                        null,
                    );
                }
                else if(adminCode !== admin.authCode) {
                    NotificationManager.error(
                        "Invalid Code",
                        "Sorry, the code you entered is invalid. Check and try again.",
                        3000,
                        null,
                        null,
                    );
                }
                else {
                  handleSwapProduct();
                }
              }}
            >
              {swaping ? 'Please wait...' : 'Swap product'}
            </Button>
            
          </ModalFooter>
        </Modal>)}

        {openPriceChangesModal && (<Modal
          style={{width: 550}}
          isOpen={openPriceChangesModal}
          toggle={togglePriceChangesModal}
          wrapClassName="modal-right"
          >
          <ModalHeader style={{alignItems:"center"}} toggle={togglePriceChangesModal}>
              <img src={`${img_base_url}${selectedProduct.thumbnail}`}  alt="Img" style={{width:60,height:60,borderRadius:5,marginRight:5,backgroundColor:'#ccc'}} />
              <Label>
                  {selectedProduct && selectedProduct.name}
              </Label>
          </ModalHeader>
          <ModalBody style={{padding:"0px"}}>              
              {(selectedProduct.priceChanges && selectedProduct.priceChanges.length > 0) && 
                <h5 style={{paddingLeft:20,paddingTop:20,color:'#008894'}}>
                  Price Changes History
                </h5>
              }

              <Colxx xxs="12">
              {(selectedProduct.priceChanges && selectedProduct.priceChanges.length > 0) ?
                  <ReactTable
                      data={selectedProduct.priceChanges}
                      columns={[
                        {
                          Header: "N#",
                          accessor: "",
                          width: 40,
                          Cell: props => <p className="">{props.row._index + 1}.</p>
                        },
                        {
                          Header: "From",
                          accessor: "oldPrice",
                          Cell: props => <p style={{color:'#a13'}}>{props.value}</p>
                        },
                        {
                          Header: "To",
                          accessor: "newPrice",
                          Cell: props => <p style={{color:'#228b22'}}>{props.value}</p>
                        },
                        {
                          Header: "Date",
                          accessor: "date",
                          width: 90,
                          Cell: props => <p className="text-muted">{__getTime(props.value)} <br/> {__getDate(props.value)}</p>
                        },
                        {
                          Header: "By",
                          accessor: "byId",
                          width:80,
                          Cell: props => <p className="text-muted">{props.value === user.id ? 'SELF' : props.original.byName}</p>
                        },
                      ]}
                      defaultPageSize={5}
                      showPageJump={true}
                      PaginationComponent={DataTablePagination}
                      showPageSizeOptions={true}
                  />
                  :
                  <h5 style={{paddingLeft:20,paddingTop:20}}>No price changes history found</h5>
              }
              </Colxx>
          </ModalBody>
        </Modal>)}

        {/* CLOSE */}
        {closeProductModal && (<Modal
          isOpen={closeProductModal}
          toggle={()=> {
            if(!closing){
              toggleCloseProductModal();
            }
          }}
        >
          <ModalHeader 
            toggle={()=> {
              if(!closing){
                toggleCloseProductModal();
              }
            }}
            style={{alignItems:"center",padding:""}} 
          >
            <Label>
              Delete {user.firstname}'s Product 
            </Label>
          </ModalHeader>
          <ModalBody>
            <h6>To delete this product - <b>{selectedProduct.name}</b> - provide your authorization code.</h6>
            {/* </b> - type <b>permanently close</b> in the box below and  */}
            <Row style={{marginTop:20}}>
              {/* <input
                disabled={closing} 
                type="text" 
                value={closeText} 
                style={{outline:'none',height:45}} 
                onChange={(event)=> setCloseText(event.target.value)}
              /> */}

              <label style={{marginLeft:20}}>
                Authorization Code:
                <input disabled={closing} type="password" value={adminCode} style={{marginLeft:20,paddingBottom:10,paddingTop:10,paddingLeft:10,outline:'none',width:80}} onChange={(event)=> setAdminCode(event.target.value)}/>
              </label>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={closing}
              color="default"
              onClick={toggleCloseProductModal}
            >
              Cancel
            </Button>{" "}
            <Button
              disabled={closing}
              color="danger"
              onClick={()=> {
                if(!adminCode){
                    NotificationManager.error(
                        "Authorization Code",
                        "Your security code required to perform this transaction. Thank you.",
                        3000,
                        null,
                        null,
                    );
                }
                else if(adminCode !== admin.authCode) {
                    NotificationManager.error(
                        "Invalid Code",
                        "Sorry, the code you entered is invalid. Check and try again.",
                        3000,
                        null,
                        null,
                    );
                }
                else {
                  handleCloseProduct();
                }
              }}
            >
              {closing ? 'Please wait...' : 'Delete Now'}
            </Button>
            
          </ModalFooter>
        </Modal>)}
    </Row>
  );
};

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;
  return {admin: user};
};

export default connect(
  mapStateToProps,
  {}
)(CustomerProducts);